"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Img = _interopRequireDefault(require("../Img"));
var _VideoLabel = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/VideoLabel"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _link = _interopRequireDefault(require("next/link"));
var _react = _interopRequireDefault(require("react"));
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
const _excluded = ["name", "place", "time", "sources", "fallback", "loading", "linkProps", "videoUrl", "icon"];
var __jsx = _react.default.createElement;
const CompanyTile = _ref => {
  let {
      name,
      place,
      time,
      sources,
      fallback,
      loading = false,
      linkProps,
      videoUrl,
      icon
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  if (loading) {
    return __jsx(_grid.Box, {
      className: "tile-l"
    }, __jsx(_grid.Box, {
      style: {
        width: '100%',
        paddingBottom: 'calc(3 / 4 * 100%)',
        position: 'relative'
      }
    }, __jsx(_grid.Box, {
      style: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }
    }, __jsx(_reactLoadingSkeleton.default, {
      width: "100%",
      height: "100%"
    }))));
  }
  if (!loading && linkProps) {
    return __jsx(_link.default, (0, _extends2.default)({}, linkProps, {
      passHref: true,
      legacyBehavior: true
    }), __jsx(Tile, (0, _extends2.default)({
      className: "tile-l"
    }, props), sources && fallback && __jsx(_Img.default, {
      layout: "ratio",
      ratio: 3 / 4,
      sources: sources,
      fallback: fallback
    }), __jsx(Overlay, null, __jsx(StyledTitle, {
      variant: "small"
    }, name), __jsx(_Body.default, {
      variant: "small"
    }, place, time && `, ${time}`)), icon && __jsx(IconWrapper, null, icon), __jsx(_VideoLabel.default, {
      videoUrl: videoUrl
    })));
  }
  return null;
};
const StyledTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "CompanyTile__StyledTitle",
  componentId: "sc-16mlha5-0"
})(["color:", ";"], ({
  theme
}) => theme.colors.neutral[0]);
const Tile = _styledComponents.default.a.withConfig({
  displayName: "CompanyTile__Tile",
  componentId: "sc-16mlha5-1"
})(["display:block;position:relative;& > img{width:100%;}"]);
const Overlay = _styledComponents.default.div.withConfig({
  displayName: "CompanyTile__Overlay",
  componentId: "sc-16mlha5-2"
})(["position:absolute;top:0;left:0;right:0;bottom:0;color:", ";display:flex;background:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.6));text-align:center;flex-direction:column;padding:4rem;justify-content:flex-end;& > * + *{margin-top:0.8rem;}"], ({
  theme
}) => theme.colors.neutral[0]);
const IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "CompanyTile__IconWrapper",
  componentId: "sc-16mlha5-3"
})(["z-index:1;position:absolute;right:1.2rem;top:1.2rem;"]);
var _default = exports.default = CompanyTile;