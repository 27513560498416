"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _link = _interopRequireDefault(require("next/link"));
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const ListPaginationLink = ({
  children,
  offset
}) => {
  const {
    query,
    pathname
  } = (0, _router.useRouter)();
  return __jsx(_link.default, {
    href: {
      pathname,
      query: _objectSpread(_objectSpread({}, query), {}, {
        offset
      })
    },
    shallow: true,
    passHref: true,
    scroll: true,
    legacyBehavior: true
  }, children);
};
var _default = exports.default = ListPaginationLink;