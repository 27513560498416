"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCompanyImageSizes = exports.default = exports.createGetServerSideProps = exports.companiesQuery = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _useThemeContext = require("@oberoninternal/travelbase-ds/hooks/useThemeContext");
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _debounce = require("debounce");
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _lodash2 = _interopRequireDefault(require("lodash.omit"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _useQueryParams = require("use-query-params");
var _companySearchParams = _interopRequireWildcard(require("../../constants/companySearchParams"));
var _createApolloClient = require("../../createApolloClient");
var _graphql = require("../../generated/graphql");
var _useComposeFilterCategory = _interopRequireDefault(require("../../hooks/search/useComposeFilterCategory"));
var _useFiltersActive = _interopRequireDefault(require("../../hooks/useFiltersActive"));
var _useQueryParams2 = _interopRequireDefault(require("../../hooks/useQueryParams"));
var _ContentWrapper = _interopRequireDefault(require("../ContentWrapper"));
var _CompanyTile = _interopRequireDefault(require("../designsystem/CompanyTile"));
var _Pagination = _interopRequireDefault(require("../designsystem/Pagination"));
var _ViewToggle = _interopRequireDefault(require("../designsystem/ViewToggle"));
var _WideGrid = _interopRequireWildcard(require("../designsystem/WideGrid"));
var _FilterList = _interopRequireDefault(require("../FilterList"));
var _Formed = _interopRequireDefault(require("../Formed"));
var _ListPaginationLink = _interopRequireDefault(require("../ListPaginationLink"));
var _Page = _interopRequireDefault(require("../Page"));
var _SearchBottomBar = _interopRequireDefault(require("../SearchBottomBar"));
var _SearchFiltersModal = _interopRequireDefault(require("../SearchFiltersModal"));
var _SearchFilterTags = _interopRequireDefault(require("../SearchFilterTags"));
var _TenantContext = require("../../context/TenantContext");
var _FavoriteIconButton = _interopRequireDefault(require("../FavoriteIconButton"));
var _useGetPageSlugByLocale = _interopRequireDefault(require("../../hooks/useGetPageSlugByLocale"));
const _excluded = ["amountPerPage", "contentWrapperVariant", "buttonTypes", "bakedInParams", "ssr", "pageTitle", "hideTitle", "totalHitsResultText"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const companiesQuery = exports.companiesQuery = (0, _client.gql)`
    query CompanySearch($params: CompanySearchParamsInput!, $limit: Int, $offset: Int, $sortByDistanceTo: LatLonInput) {
        companies(params: $params, limit: $limit, offset: $offset, sortByDistanceTo: $sortByDistanceTo) {
            ... on CompanySearchResult {
                totalHits
                hits {
                    ...CompanyHit
                }

                placeCounts {
                    value
                    hits
                }
                themeCounts {
                    value
                    hits
                }
                categoryCounts {
                    value
                    hits
                }
                mainCategoryCounts {
                    value
                    hits
                }
                targetAudienceCount {
                    value
                    hits
                }
            }
        }
    }

    fragment CompanyHit on Company {
        name
        slug
        id
        place
        targetAudiences
        videoUrl
        categories {
            slug
        }
        mainImage {
            transform(config: HERO, format: JPEG) {
                ...ListImage
            }
        }
        webpMainImage: mainImage {
            transform(config: HERO, format: WEBP) {
                ...ListImage
            }
        }
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
        normalOperatingHours {
            monday {
                timeOpen
                timeClosed
            }
            tuesday {
                timeOpen
                timeClosed
            }
            wednesday {
                timeOpen
                timeClosed
            }
            thursday {
                timeOpen
                timeClosed
            }
            friday {
                timeOpen
                timeClosed
            }
            saturday {
                timeOpen
                timeClosed
            }
            sunday {
                timeOpen
                timeClosed
            }
        }
        specialOperatingHours {
            startDate
            endDate
            monday {
                timeOpen
                timeClosed
            }
            tuesday {
                timeOpen
                timeClosed
            }
            wednesday {
                timeOpen
                timeClosed
            }
            thursday {
                timeOpen
                timeClosed
            }
            friday {
                timeOpen
                timeClosed
            }
            saturday {
                timeOpen
                timeClosed
            }
            sunday {
                timeOpen
                timeClosed
            }
        }
    }

    query CompanyFilters {
        companyCategories {
            name
            slug
            position
            children {
                name
                slug
                position
            }
        }
        places {
            id
            name
        }
    }
`;
const useCompanyImageSizes = () => {
  const {
    mediaQueries
  } = (0, _useThemeContext.useThemeContext)();
  return `(min-width: ${mediaQueries.xxl}) 25vw, (min-width: ${mediaQueries.xl}) 25vw, (min-width: ${mediaQueries.s}) 25vw, 100vw`;
};
exports.useCompanyImageSizes = useCompanyImageSizes;
const filterKeys = ['categorySlugs', 'placeId', 'mainCategorySlugs'];
const Companies = _ref => {
  let {
      amountPerPage,
      contentWrapperVariant,
      buttonTypes,
      bakedInParams,
      ssr = false,
      pageTitle,
      hideTitle = false,
      totalHitsResultText = false
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    colors
  } = (0, _useThemeContext.useThemeContext)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const intl = (0, _reactIntl.useIntl)();
  const sizes = useCompanyImageSizes();
  const filterModal = (0, _useSesame.default)();
  const [params, setParams] = (0, _useQueryParams2.default)(_companySearchParams.default);
  const [filtersActive, removeActiveFilters] = (0, _useFiltersActive.default)(_companySearchParams.companySearchFilterParams);
  const paramsSameAsBakedIn = (0, _react.useMemo)(() => (0, _lodash.default)(params, bakedInParams), [params, bakedInParams]);
  const {
    getPageSlugByLocale
  } = (0, _useGetPageSlugByLocale.default)();
  const companyPageSlug = getPageSlugByLocale('COMPANY');
  const startParams = params.searched ? {} : rest.startParams;
  const {
    offset = 0,
    categorySlugs = [],
    placeId,
    mainCategorySlugs = []
  } = _objectSpread(_objectSpread(_objectSpread({}, startParams), params), bakedInParams);
  const paramsInput = {
    mainCategorySlugs,
    categorySlugs,
    placeId
  };
  const filtersQuery = (0, _graphql.useCompanyFiltersQuery)({
    ssr
  });
  const {
    data,
    loading
  } = (0, _graphql.useCompanySearchQuery)({
    variables: {
      offset: offset >= 0 ? offset : 0,
      limit: amountPerPage,
      params: paramsInput
    },
    ssr
  });
  const handleSubmit = (0, _react.useCallback)((values, helpers) => {
    // by changing the params, apollo will refetch the query automagically
    setParams({
      categorySlugs: values.categorySlugs ?? [],
      mainCategorySlugs: values.mainCategorySlugs ?? [],
      offset: 0,
      placeId: values.placeId?.[0],
      searched: true
    }, 'replaceIn');
    setTimeout(() => helpers.setSubmitting(false));
  }, [setParams]);
  // TODO use useMemo in order te create the function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAutoSave = (0, _react.useCallback)((0, _debounce.debounce)(handleSubmit), [handleSubmit]);
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const shouldAutoSave = deviceSize !== 'mobile';
  const mainCompanyFilterCategory = (0, _react.useMemo)(() => (0, _useComposeFilterCategory.default)({
    categoryHandle: 'mainCategorySlugs',
    categoryLabel: intl.formatMessage({
      "id": "5hM5o3",
      "defaultMessage": [{
        "type": 0,
        "value": "Categorie"
      }]
    }),
    filterProperties: [...(filtersQuery.data?.companyCategories ?? [])].sort((categoryA, categoryB) => categoryA.position - categoryB.position),
    filterCounts: data?.companies.mainCategoryCounts ?? [],
    filterCountKey: 'value',
    filterResolver: ({
      slug,
      name
    }) => ({
      handle: slug,
      label: name ?? ''
    })
  }), [data, filtersQuery.data, intl]);
  const subCompanyFilterCategory = (0, _react.useMemo)(() => (0, _useComposeFilterCategory.default)({
    categoryHandle: 'categorySlugs',
    categoryLabel: intl.formatMessage({
      "id": "5hM5o3",
      "defaultMessage": [{
        "type": 0,
        "value": "Categorie"
      }]
    }),
    filterProperties: mainCategorySlugs.length + categorySlugs.length ? [...(filtersQuery.data?.companyCategories.flatMap(category => category.children) ?? [])].sort((categoryA, categoryB) => categoryA.position - categoryB.position) : [],
    filterCounts: data?.companies.categoryCounts ?? [],
    filterCountKey: 'value',
    filterResolver: ({
      slug,
      name
    }) => ({
      handle: slug,
      label: name ?? ''
    })
  }), [categorySlugs.length, data, filtersQuery.data, intl, mainCategorySlugs.length]);
  const placesFilterCategory = (0, _react.useMemo)(() => (0, _useComposeFilterCategory.default)({
    categoryHandle: 'placeId',
    categoryLabel: intl.formatMessage({
      "id": "etjfNt",
      "defaultMessage": [{
        "type": 0,
        "value": "Plaats"
      }]
    }),
    filterProperties: filtersQuery.data?.places ?? [],
    filterCounts: data?.companies.placeCounts ?? [],
    filterCountKey: 'value',
    filterResolver: ({
      id,
      name
    }) => ({
      handle: id,
      label: name
    })
  }), [data, filtersQuery.data, intl]);
  const allFilterCategories = (0, _react.useMemo)(() => [mainCompanyFilterCategory, subCompanyFilterCategory, placesFilterCategory], [mainCompanyFilterCategory, placesFilterCategory, subCompanyFilterCategory]);
  // when a mainCategory is selected it's hidden from the UI
  const filterCategories = allFilterCategories.filter(category => category.handle !== 'mainCategorySlugs' || !mainCategorySlugs.length);
  const {
    totalHits = 0
  } = data?.companies ?? {};
  const pageCount = Math.ceil(totalHits / amountPerPage);
  const currentPageIndex = Math.floor(offset / amountPerPage);
  return __jsx(_Page.default, {
    id: "search-page"
  }, __jsx(_Formed.default, {
    skipPrompt: true,
    initialValues: {
      offset,
      mainCategorySlugs,
      categorySlugs,
      placeId: placeId ? [placeId] : []
    },
    handleAutoSave: shouldAutoSave ? (values, helpers) => {
      helpers.setSubmitting(true);
      handleAutoSave(values, helpers);
    } : undefined,
    handleSubmit: handleSubmit,
    enableReinitialize: true
  }, ({
    isSubmitting
  }) => {
    const isLoading = loading || isSubmitting;
    return __jsx(StyledContentWrapper, {
      variant: contentWrapperVariant
    }, __jsx(_grid.Flex, {
      flexDirection: ['column', null, null, 'row']
    }, __jsx(LeftBar, {
      className: contentWrapperVariant === 'searchMap' ? 'gt-m' : undefined
    }, __jsx(SectionHeader, {
      className: "gt-m",
      hideTitle: hideTitle
    }, loading ? __jsx(_reactLoadingSkeleton.default, {
      height: "1em",
      width: 250
    }) : __jsx(_Title.default, {
      elementType: "h2",
      style: {
        fontWeight: 400
      }
    }, data?.companies.totalHits === 1 ? __jsx(_react.default.Fragment, null, __jsx("span", {
      style: {
        color: colors.secondary['40']
      }
    }, data?.companies.totalHits), ' ', totalHitsResultText ? __jsx(_reactIntl.FormattedMessage, {
      id: "iD7B7s",
      defaultMessage: [{
        "type": 0,
        "value": "resultaat"
      }]
    }) : __jsx(_reactIntl.FormattedMessage, {
      id: "hkompm",
      defaultMessage: [{
        "type": 0,
        "value": "bedrijf"
      }]
    })) : __jsx(_react.default.Fragment, null, __jsx("span", {
      style: {
        color: colors.secondary['40']
      }
    }, data?.companies.totalHits), ' ', pageTitle ?? (totalHitsResultText ? __jsx(_reactIntl.FormattedMessage, {
      id: "eZjfSC",
      defaultMessage: [{
        "type": 0,
        "value": "resultaten"
      }]
    }) : __jsx(_reactIntl.FormattedMessage, {
      id: "/PwKlV",
      defaultMessage: [{
        "type": 0,
        "value": "bedrijven"
      }]
    }))))), __jsx(_SearchFilterTags.default, {
      shouldAutoSave: shouldAutoSave,
      categories: allFilterCategories
    }), filtersQuery.loading && __jsx(_FilterList.default, {
      loading: true
    }), !filtersQuery.loading && __jsx(_FilterList.default, {
      categories: filterCategories,
      className: "gt-m"
    })), __jsx(_SearchBottomBar.default, {
      allowedActions: buttonTypes,
      filtersActive: filtersActive,
      onFiltersClick: filterModal.onOpen
    }), __jsx(_SearchFiltersModal.default, {
      dirtyCheckKeys: filterKeys,
      filtersActive: filtersActive,
      onClose: filterModal.onClose,
      open: filterModal.open,
      removeActiveFilters: removeActiveFilters
    }, __jsx(_FilterList.default, {
      categories: filterCategories
    })), __jsx(ListingSection, null, !hideTitle && __jsx(SectionHeader, {
      className: "gt-m"
    }, __jsx(_Title.default, null, !isLoading && data?.companies.hits && data?.companies.hits.length === 0 ? __jsx(_reactIntl.FormattedMessage, {
      id: "ymtD1z",
      defaultMessage: [{
        "type": 0,
        "value": "Geen "
      }, {
        "type": 1,
        "value": "pageTitle"
      }, {
        "type": 0,
        "value": " gevonden"
      }],
      values: {
        pageTitle: pageTitle ?? intl.formatMessage({
          "id": "/PwKlV",
          "defaultMessage": [{
            "type": 0,
            "value": "bedrijven"
          }]
        })
      }
    }) : __jsx(_reactIntl.FormattedMessage, {
      id: "OMgKIV",
      defaultMessage: [{
        "type": 1,
        "value": "pageTitle"
      }, {
        "type": 0,
        "value": " overzicht"
      }],
      values: {
        pageTitle: pageTitle ? pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1) : intl.formatMessage({
          "id": "/PwKlV",
          "defaultMessage": [{
            "type": 0,
            "value": "bedrijven"
          }]
        })
      }
    }))), __jsx(_Body.default, {
      className: "lt-m",
      variant: "large",
      style: {
        fontWeight: 400,
        marginTop: '2.4rem'
      }
    }, data?.companies.totalHits === 1 ? __jsx(_react.default.Fragment, null, __jsx("span", {
      style: {
        color: colors.secondary['40']
      }
    }, data?.companies.totalHits), ' ', __jsx(_reactIntl.FormattedMessage, {
      id: "CjYMRd",
      defaultMessage: [{
        "type": 0,
        "value": "Bedrijf"
      }]
    })) : __jsx(_react.default.Fragment, null, __jsx("span", {
      style: {
        color: colors.secondary['40']
      }
    }, data?.companies.totalHits), ' ', __jsx(_reactIntl.FormattedMessage, {
      id: "v93Tug",
      defaultMessage: [{
        "type": 0,
        "value": "Bedrijven"
      }]
    }))), !isLoading && data?.companies.hits && data?.companies.hits.length === 0 && filtersActive && !paramsSameAsBakedIn && __jsx(_Button.default, {
      style: {
        alignSelf: 'flex-start'
      },
      variant: "outline",
      onClick: removeActiveFilters,
      type: "button"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "+PgRNi",
      defaultMessage: [{
        "type": 0,
        "value": "Filters verwijderen"
      }]
    })), __jsx(_WideGrid.default, {
      widths: [100, 50, 100 / 3]
    }, isLoading && new Array(amountPerPage).fill(null).map((_, i) => __jsx(_WideGrid.Cell, {
      key: i
    }, __jsx(_CompanyTile.default, {
      key: i,
      loading: true
    }))), !isLoading && data?.companies.hits && data?.companies.hits.length > 0 && data?.companies.hits.map(company => __jsx(_WideGrid.Cell, {
      key: company.slug
    }, __jsx(_CompanyTile.default, {
      sources: [{
        srcSet: company.webpListImage?.transform?.srcSet ?? company.webpMainImage?.transform?.srcSet,
        type: 'image/webp',
        sizes
      }, {
        srcSet: company.listImage?.transform?.srcSet ?? company.mainImage?.transform?.srcSet,
        type: 'image/jpeg',
        sizes
      }],
      fallback: {
        src: company.listImage?.transform?.src ?? company.mainImage?.transform?.src
      },
      icon: brandConfig.enableFavorites && __jsx(_FavoriteIconButton.default, {
        type: "company",
        id: company.id
      }),
      videoUrl: company.videoUrl,
      name: company.name,
      place: company.place,
      loading: isLoading,
      linkProps: {
        href: {
          pathname: `/${companyPageSlug}/${company.slug}`
        },
        locale: intl.locale
      }
    })))), __jsx(StyledPagination, {
      currentPageIndex: currentPageIndex,
      pagesCount: pageCount,
      LinkComponent: _ListPaginationLink.default
    }))));
  }));
};
var _default = exports.default = Companies;
const createGetServerSideProps = ({
  amountsPerPage,
  startParams = {},
  bakedInParams = {},
  client: existingClient
}) => async context => {
  const query = 'query' in context ? context.query : context.params;
  const decodedParams = (0, _useQueryParams.decodeQueryParams)(_companySearchParams.default, (0, _lodash2.default)(query, ['locale', ...Object.keys(bakedInParams)]));
  const queryParams = _objectSpread(_objectSpread(_objectSpread({}, decodedParams.searched ? {} : startParams), decodedParams), bakedInParams);
  const {
    categorySlugs,
    placeId,
    offset = 0
  } = queryParams;
  const paramsInput = {
    categorySlugs,
    placeId
  };
  const client = existingClient ?? (0, _createApolloClient.initApolloClient)(context.locale);
  await client.query({
    query: _graphql.CompanySearchDocument,
    variables: {
      // some protection as an offset < 0 will let the BE explode 🧨
      offset: offset >= 0 ? offset : 0,
      limit: amountsPerPage,
      params: paramsInput
    }
  });
  return (0, _createApolloClient.addApolloState)(client, {
    props: {
      bakedInParams,
      startParams
    }
  });
};
exports.createGetServerSideProps = createGetServerSideProps;
const StyledContentWrapper = (0, _styledComponents.default)(_ContentWrapper.default).withConfig({
  displayName: "Companies__StyledContentWrapper",
  componentId: "utcup8-0"
})(["padding-bottom:4rem;@media screen and (min-width:", "){padding-bottom:8rem;}"], ({
  theme
}) => theme.mediaQueries.s);
const StyledViewToggle = (0, _styledComponents.default)(_ViewToggle.default).withConfig({
  displayName: "Companies__StyledViewToggle",
  componentId: "utcup8-1"
})([""]);
const SectionHeader = _styledComponents.default.header.withConfig({
  displayName: "Companies__SectionHeader",
  componentId: "utcup8-2"
})(["display:flex;grid-column:1 / -1;margin:", ";align-items:center;@media screen and (min-width:", "){margin:", ";height:4.8rem;", "{margin-left:auto;}}"], ({
  hideTitle
}) => hideTitle ? '0 0 4rem 0' : '4rem 0', ({
  theme
}) => theme.mediaQueries.xs, ({
  hideTitle
}) => hideTitle ? '0 0 4rem 0' : '4rem 0', StyledViewToggle);
const LeftBar = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Companies__LeftBar",
  componentId: "utcup8-3"
})(["flex-shrink:0;@media screen and (min-width:", "){width:29.6rem;padding-right:", ";", "{margin-bottom:2.4rem;}}@media screen and (min-width:", "){width:32.8rem;}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['60_Large'], SectionHeader, ({
  theme
}) => theme.mediaQueries.xl);
const ListingSection = _styledComponents.default.section.withConfig({
  displayName: "Companies__ListingSection",
  componentId: "utcup8-4"
})(["width:100%;"]);
const StyledPagination = (0, _styledComponents.default)(_Pagination.default).withConfig({
  displayName: "Companies__StyledPagination",
  componentId: "utcup8-5"
})(["margin-top:4rem;"]);