"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Cell = exports.CarouselGrid = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const baseGridCss = (0, _styledComponents.css)(["--gap:4rem;--row-gap:6.4rem;display:flex;flex-wrap:wrap;margin-left:calc(var(--gap) * -0.5);margin-right:calc(var(--gap) * -0.5);margin-top:calc(var(--row-gap) * -0.5);box-sizing:content-box;& > *{padding:0 calc(var(--gap) * 0.5);margin-top:calc(var(--row-gap) * 0.5);}"]);
const responsiveGridCss = (0, _styledComponents.css)(["", ""], ({
  widths,
  theme
}) => widths && `
            & > * {
                width: ${widths[0]}%;
            }

            @media screen and (min-width: ${theme.mediaQueries.s}) {
                & > * {
                    width: ${widths[Math.min(1, widths.length - 1)]}%;
                }
            }

            @media screen and (min-width: ${theme.mediaQueries.xl}) {
                & > * {
                    width: ${widths[Math.min(2, widths.length - 1)]}%;
                }
            }

            @media screen and (min-width: ${theme.mediaQueries.xxl}) {
                & > * {
                    width: ${widths[Math.min(3, widths.length - 1)]}%;
                }
            }
     `);
const calcCarouselWidth = (percentage, length) => {
  const p = percentage / 100;
  return `
        width: calc(${percentage * length}% + var(--gap) * ${p * length});
    `;
};
const responsiveCarouselGridCss = (0, _styledComponents.css)(["", ""], ({
  widths,
  length,
  theme
}) => widths && length && `
            ${calcCarouselWidth(widths[0], length)}

            @media screen and (min-width: ${theme.mediaQueries.s}) {
                ${calcCarouselWidth(widths[Math.min(1, widths.length - 1)], length)}
            }

            @media screen and (min-width: ${theme.mediaQueries.xl}) {
               ${calcCarouselWidth(widths[Math.min(2, widths.length - 1)], length)}
            }

            @media screen and (min-width: ${theme.mediaQueries.xxl}) {
               ${calcCarouselWidth(widths[Math.min(3, widths.length - 1)], length)}
            }

            @media screen and (min-width: ${theme.mediaQueries.xxxl}) {
               ${calcCarouselWidth(widths[Math.min(4, widths.length - 1)], length)}
            }
     `);
const WideGrid = _styledComponents.default.div.withConfig({
  displayName: "WideGrid",
  componentId: "sc-18nmwia-0"
})(["", " ", ""], baseGridCss, responsiveGridCss);
const CarouselGrid = exports.CarouselGrid = _styledComponents.default.div.withConfig({
  displayName: "WideGrid__CarouselGrid",
  componentId: "sc-18nmwia-1"
})(["", " margin-left:calc(var(--gap) * -1);margin-right:0;& > *{scroll-snap-align:center;width:", "%;:first-of-type{padding-left:var(--gap);}@media screen and (min-width:", "){padding-left:var(--gap);}@media screen and (min-width:", "){scroll-snap-align:end;}padding-right:0;}", ""], baseGridCss, ({
  length
}) => 100 / length, ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.xl, responsiveCarouselGridCss);
const Cell = exports.Cell = _styledComponents.default.div.withConfig({
  displayName: "WideGrid__Cell",
  componentId: "sc-18nmwia-2"
})([""]);
var _default = exports.default = WideGrid;